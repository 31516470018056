<template>
    <div style="padding-top: 8em;">
        <section class="intro clearfix header_service">
            <div class="container" style="max-width:65em; min-height:20em">
                <div class="row">
                <div class="overlay-green"></div>
                    <v-col class="col-md-8 intro-info text-left align-self-center" md="8" style="z-index: 1;">
                        <h1 class="text-uppercase bold text-white mb-4 ls-1 w-75">Penutupan Perusahaan</h1>
                        <p class="mb-5 text-white h5">Legalpedia siap melayani Jasa Penutupan Perusahaan sesuai kebutuhan Anda. <br> <br>
                            LegalPedia melayani penutupan perusahaan dalam menyelesaikan administrasi perusahaan untuk menghindari hutang pajak serta menghentikan kewajiban pelaporan pajak.
                        </p>
                    </v-col>
                    <v-col class="col-md-4" md="4" style="z-index: 1;">
                        <div class="card text-darkgreen">
                            <div class="card-header pt-5 pb-5 border-0">
                                <span>Mulai dari</span>
                                <p class="mt-2 text-center mb-0 pb-0"><s>10.000.000</s></p>
                                <h3 class="text-center mb-0">
                                    IDR 8.000.000
                                </h3>
                            </div>
                            <div class="card-body">
                                <v-btn block small color="primary" class="p-4">Dapatkan Sekarang</v-btn>
                            </div>
                        </div>
                    </v-col>
                </div>
            </div>
        </section>
        <section>
            <v-container class="mt-2">
                <v-row>
                    <v-col cols="12" class="mb-0 mt-0">
                        <v-breadcrumbs :items="breadcumbs" class="mb-1 pb-1 breadcrumb">
                            <template v-slot:item="{ item }">
                                <v-breadcrumbs-item
                                    :to="item.href"
                                    class="text-subtitle-2 crumb-item "
                                    :disabled="item.disabled"
                                    exact
                                >
                                    {{ item.text }}
                                </v-breadcrumbs-item>
                            </template>
                        </v-breadcrumbs>
                    </v-col>
                </v-row>
            </v-container>
        </section>
        <section class="mt-8 mb-8" id="start">
            <v-container class="container">
                <v-row align="center" justify="center">
                    <div class="col-md-4 mb-5" v-for="(detail, index) in services" :key="index">
                        <div class="card sub-service-card">
                            <div class="card-body">
                                <img :src="detail.image" class="center mb-3 img-fluid" v-if="detail.image">
                                <img src="@/assets/pt.png" class="center mb-3 img-fluid" v-else>
                                <a href="">
                                </a>
                                <a href="" class="text-center">
                                    <h5>{{detail.title}}</h5>
                                </a>
                                <p class="mb-1 text-center">{{detail.description}}</p>
                                

                                <div class="w-100 mt-3" style="height:100px">
                                    <div class="price-tag w-100 text-center">
                                        <span class="small"><s>{{detail.discount_price }}</s></span>
                                        <h5 class="bold mb-0 mt-0">IDR {{detail.price}}</h5>
                                    </div>
                                </div>
                            </div>
                            <div class="card-footer border-0 d-flex jutify-content-between">
                                <div class="price-tag w-75">
                                    <h6 class="bold text--primary mt-2">Hubungi Kami</h6>
                                </div>
                                <div class="w-25">
                                    <a :href="detail ? detail.url_link_wa : 'https://api.whatsapp.com/send?phone=+6282120938886&text=Hi%20LegalPedia,%20Saya%20ingin%20konsultasi%20.%20Mohon%20Dibantu'" target="_blank" class="button btn btn-danger mt-1">
                                        <v-icon color="#fff" small>
                                            mdi-arrow-right
                                        </v-icon>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </v-row>
            </v-container>
        </section>
        <contact-us></contact-us>
    </div>
</template>
<script>
import { mapState } from 'vuex'

import ContactUs from "@/components/layout_front/ContactUs.vue"
import HeaderService from "@/components/layout_front/HeaderService.vue"
import {service_item_api} from "@/backend-api/service_item_api"

export default {
    components: {
        ContactUs, HeaderService
    },
    data: () => ({
        data : '',
        panel:[0,1,2,3],
        breadcumbs: [
            {
                text: 'Home',
                disabled: false,
                href: '/',
            },
            {
                text: 'Penutupan Perusahaan',
                disabled: true,
            }
        ],
        services: []
    }),
    methods:{
        async fetchData(){
            var respData = await service_item_api.front(`?service_id=6`, null, false, false, false)
            if (respData.status === 200) {
                this.services = respData.data.data
            } 
        },
    },
    async mounted(){
        await this.fetchData()
    },
    watch: {
    }
}
</script>

<style scoped>

</style>